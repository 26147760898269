<template>
  <div class="wish-item">
    <div class="wish-item__top row">
      <div class="wish-item__top-list">
        <div class="wish-item__top-item">
          <p>
            NDC:
          </p>
          <span>
            <b>{{item.ndc}}</b>
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            Product name:
          </p>
          <span>
            {{item.product_name}}
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            Strength:
          </p>
          <span>
            {{item.strength}}
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            Pack Size:
          </p>
          <span>
            {{item.pack_size}}
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            Dosage:
          </p>
          <span>
            {{item.dosage_form}}
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            MFR:
          </p>
          <span>
            {{item.manufacturer_name}}
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            My Price:
          </p>
          <span> 
            <small v-if="item.price_from">{{item.price_from}}</small>
            <small v-else>0</small> 
            -
            <small v-if="item.price_to">{{item.price_to}}</small>
            <small v-else>0</small>
          </span>
        </div>
        <div class="wish-item__top-item">
          <p>
            Action:
          </p>
          <span class="wish-item__remove" @click="deleteItem(item)">
            X
          </span>
        </div>
        <div 
          v-if='item.products.length'
          class="arrow-open"
          @click="choiseOpenItem(item)" 
          :class="{'is-open': item.id === openId }"
          >
          <inline-svg :src="require('@/assets/images/svg/order-arrow.svg')"></inline-svg>
        </div>
      </div>
    </div>
    <div 
      class="wish-item__bottom" 
      :class="{'is-open': item.id === openId }"
      v-for="(itemProduct, index) in item.products"
      :key="index"
    >
      <div class="wish-item__product">
        <div class="wish-item__product-left">
          <div class="wish-item__product-title">
            Prezcobix Oral Tablet,
            film coated
          </div>
          <div class="wish-item__product-ndc">NDC {{itemProduct.ndc}}</div>
        </div>
        <div class="wish-item__product-medium">
          <div class="wish-item__product-label">
            <p>Pack Quantity</p>
            <span>{{itemProduct.pack_quantity}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>STRENGTH</p>
            <span>{{itemProduct.strength}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>PACK SIZE</p>
            <span>{{itemProduct.pack_size}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>DOSAGE FORM</p>
            <span>{{itemProduct.dosage_form}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>MFR</p>
            <span>{{itemProduct.manufacturer_name}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>EXP DATE</p>
            <span>{{itemProduct.expiration_date}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>lot</p>
            <span>{{itemProduct.lot}}</span>
          </div>
          <div class="wish-item__product-label">
            <p>qty avail</p>
            <span>1</span>
          </div>
        </div>
        <div class="wish-item__product-right">
          <p>Seller's price</p>
          <div class="wish-item__product-price">
            <div class="wish-item__item-price">
              ${{itemProduct.price}}
            </div>
            <div class="wish-item__item-count">
              <input type="text" value="1" v-mask="'###'">
              <div class="wish-item__item-match">
                <div class="wish-item__item-plus" >
                  <inline-svg :src="require(`@/assets/images/svg/arrow-input-top.svg`)"></inline-svg>
                </div>
                <div class="wish-item__item-minus" >
                  <inline-svg :src="require(`@/assets/images/svg/arrow-input-down.svg`)"></inline-svg>
                </div>
              </div>
            </div>
          </div>
          <div class="wish-item__item-bottom">
            <button class="btn add">Add to cart</button>
          </div>
        </div>
      </div>
      <div class="wish-item__product">
        <div class="wish-item__product-left">
          <div class="wish-item__product-title">
            Prezcobix Oral Tablet,
            film coated
          </div>
          <div class="wish-item__product-ndc">NDC 71776-0035-05</div>
        </div>
        <div class="wish-item__product-medium">
          <div class="wish-item__product-label">
            <p>PAk Quantity</p>
            <span>full</span>
          </div>
          <div class="wish-item__product-label">
            <p>STRENGTH</p>
            <span>3 MG / 0.5 MG	</span>
          </div>
          <div class="wish-item__product-label">
            <p>PACK SIZE</p>
            <span>100</span>
          </div>
          <div class="wish-item__product-label">
            <p>DOSAGE FORM</p>
            <span>Suspension / Drops</span>
          </div>
          <div class="wish-item__product-label">
            <p>MFR</p>
            <span>full</span>
          </div>
          <div class="wish-item__product-label">
            <p>EXP DATE</p>
            <span>Eyevance Pharmaceuticals, LLC</span>
          </div>
          <div class="wish-item__product-label">
            <p>Supplier</p>
            <span>12/23</span>
          </div>
          <div class="wish-item__product-label">
            <p>qty avail</p>
            <span>1</span>
          </div>
        </div>
        <div class="wish-item__product-right">
          <p>Seller's price</p>
          <div class="wish-item__product-price">
            <div class="wish-item__item-price">
              $1463.47
            </div>
            <div class="wish-item__item-count">
              <input type="text" value="1" v-mask="'###'">
              <div class="wish-item__item-match">
                <div class="wish-item__item-plus" >
                  <inline-svg :src="require(`@/assets/images/svg/arrow-input-top.svg`)"></inline-svg>
                </div>
                <div class="wish-item__item-minus" >
                  <inline-svg :src="require(`@/assets/images/svg/arrow-input-down.svg`)"></inline-svg>
                </div>
              </div>
            </div>
          </div>
          <div class="wish-item__item-bottom">
            <button class="btn add">Add to cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
  props:['item'],
  data(){
    return{
      openId: null,
    }
  },
  methods:{
    choiseOpenItem(item){
      if(this.openId === item.id){
         this.openId = null
       }else{
        this.openId = item.id
      }
    },
    deleteItem(item){
      this.removeWishItem(item.id).then(res=>{
         if(res.status){
          this.$toasted.success('Success removed from wishlist',{
            duration: 3000
          }) 
          this.getWishlist(); 
          }else{
            this.$toasted.error(JSON.stringify(res.data), {
              duration: 3000
            })
          }
      })
    },
    ...mapActions({
      removeWishItem: 'general/removeWishItem',
        getWishlist: 'general/getWishlist'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.wish-item{
  border-radius: 27px;
  background-color: $white-grey;
  &__top{
    position: relative;
    padding:35px 60px 25px 20px;
    p{
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      opacity: 0.5;
      margin-bottom: 20px;
    }
    span{
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      &.wish-item__number{
        font-size: 18px;
        font-weight: 700;
      }
      &.wish-item__price{
        font-size: 24px;
        font-weight: 700;
      }
      &.wish-item__status{
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        padding: 3.5px 20px 5px;
        border-radius: 19px;
        background-color: $grey;
        color: $white;
        min-width: 120px;
        text-align: center;
        &.black{
          background-color: $grey;

        }
        &.red{
          background-color: $red;
        }
        &.green{
          background-color: $green;
        }
      }
    }
  }
  &__bottom{
    max-height: 0;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    transition: 0.3s all;
    overflow: hidden;
    &.is-open{
      max-height: 1000px;
      overflow: visible;
      border-top: 1px solid $grey02;
      transition: 0.5s all;
    }
  }
  &__list{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  &__list-item{
    display: flex;
    font-family: 'Inter', Arial;
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    p{
      width: 50%;
    }
    span{
      width: 50%;
    }
  }

  .arrow-open{
    position: absolute;
    right: -30px;
    height: 40px;
    top: calc(50% - 20px);
    cursor: pointer;
    transition: 0.5s all;
    &.is-open{
      transform: rotate(90deg);
    }
    svg{
      opacity: 0.2;
    }
  }
  &__items{
    background-color: white;
    transition: 0.3s all;
    padding: 20px 30px 40px;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    border-radius: 0 0 27px 27px;
    .item-block{
      margin: 0 -10px 0 -10px;
      &__title{
        font-size: 16px;
      }
      &__info{
        margin-bottom: 25px;
      }
      &__info-item{
        padding-bottom: 3px;
        p,span{
          font-size: 13px;
        }
      }
    }
  }
  &__discount{
    font-family: 'Inter', Arial;
    font-weight: 900;
    font-size: 28px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span{
      padding: 15px 30px;
      background-color: $yellow;
      color: white;
      border-radius: 24px;
    }
  }
  &__info{
    padding: 10px 15px 20px 40px;
  }
  &__top-list{
    display: flex;
    position: relative;
    justify-content: space-between;
    @media screen and (max-width: 992px){
      flex-wrap: wrap;
    }
  }
  &__top-item{
    padding: 0 15px;
    @media screen and (max-width: 992px){
      margin-bottom: 15px;
    }
  }
  &__product{
    display: flex;
    margin: 20px;
    justify-content: space-between;
    background-color: $white;
    padding: 25px;
    box-shadow: 0px 4px 24px rgba(59, 64, 240, 0.05), 0px 2.01488px 10.2028px rgba(59, 64, 240, 0.0196777), 0px 1.01723px 6.36824px rgba(59, 64, 240, 0.0115755), 0px 0.568403px 4.05633px rgba(59, 64, 240, 0.00785275), 0px 0.334767px 2.49234px rgba(59, 64, 240, 0.00625354), 0px 0.190601px 1.38285px rgba(59, 64, 240, 0.00576369), 0px 0.086378px 0.580624px rgba(59, 64, 240, 0.00549511);
    border-radius: 27px;
    @media screen and (max-width: 992px){
      flex-direction: column;
    }
  }
  &__product-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 30%;
    padding-right: 25px;
    @media screen and (max-width: 992px){
      max-width: 100%;
      padding-right: 0;
    }
  }
  &__product-title{
    font-family: 'Inter', Arial;
    font-size: 22px;
    font-weight: 700;
    color: $grey;
    margin-bottom: 20px;
    padding-top: 15px;
  }
  &__product-ndc{
    font-size: 16px;
    font-family: 'Inter', Arial;
    font-weight: 500;
    color: $grey;
    background: #F7F7F7;
    border-radius: 13px;
    padding: 15px;
    margin-bottom: 15px;
  }
  &__product-medium{
    min-width: 47%;
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    box-sizing: border-box;
    border-left: 1px dashed $grey02;
    border-right: 1px dashed $grey02;
    @media screen and (max-width: 992px){
      max-width: 100%;
      padding: 15px 0;
      border-left: 0;
      border-right: 0;
    }
  }
  &__product-label{
    display: flex;
    justify-content: space-between;
    p{
      width: 50%;
      color: $grey05;
      font-size: 12px;
      font-family: 'Inter', Arial;
      text-transform: uppercase;
    }
    span{
      font-size: 16px;
      font-family: 'Inter', Arial;
      width: 46%;
      color: $grey;

    }
  }
  &__product-right{
    min-width: 27%;
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    justify-content: space-between;
    @media screen and (max-width: 992px){
      max-width: 100%;
      padding: 15px 0;
    }
    &>p{
      font-size: 16px;
      font-family: 'Inter', Arial;
      color: $grey;
      margin-top: 10px;
    }
  }

  &__item-count{
    height: 48px;
    width: 100px;
    background-color: $white-grey;
    border: 1px solid rgba(223, 223, 223, 0.5);
    box-sizing: border-box;
    border-radius: 13px;
    position: relative;
    input{
      width: 100%;
      height: 100%;
      background-color: transparent;
      font-family: 'Inter', Arial;
      font-style: normal;
      font-weight: 300;
      font-size: 26px;
      line-height: 21px;
      opacity: 0.5;
      padding: 0 30px;
    }
  }
  &__item-left{
    align-self: flex-start;
  }
  &__product-price{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  &__item-price{
    font-size: 26px;
    font-family: 'Inter', Arial;
    font-weight: 600;
  }
  &__item-match{
    position: absolute;
    right: 10px;
    top: 7px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &__item-plus,
  &__item-minus{
    height: 12px;
    opacity: 0.6;
    &:hover{
      opacity: 1;
    }
  }
  &__item-bottom{
    margin-bottom: 15px;
  }
  &__remove{
    margin-left: 15px;
    cursor:pointer;
    padding: 3px;
    &:hover{
      text-shadow: 0 0 3px 1px rgba(0,0,0,0.1)
    }
  }
}
</style>
